<template>
  <div id="app">
    <div class="app">
      <div class="app">
        <div class="header">
          <div class="year-old">18+</div>
        </div>
        <p class="warning">本网站包含成人访问内容资料。 您需要年满十八（18）岁才能使用本网站。</p>
        <div class="answere">
          <div class="btn-wrap">
            <button
                @click="handleClickTo"
                type="button"
                :class="aa"
                class="van-button van-button--warning van-button--large van-button--block van-button--round"
            >
              <div class="van-button__content">
                <!---->
                <span class="van-button__text">已满18点我访问</span>
                <!---->
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      r: '',
      code: '',
      domain: 'clqycc.cn' //落地页域名
    }
  },
  computed: {
    aa() {
      return [this.code && this.r === this.code ? 'aa0' : '']
    }
  },
  mounted() {

  },
  methods: {
    handleClickTo() {
      // 验证是手机还是PC
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        window.location.replace('https://' + this.generateRandomString(10) + '.' + this.domain + '/index1/' + window.location.search)
      } else {
        // 跳转
        window.location.replace('https://' + this.generateRandomString(10) + '.' + this.domain + '/web/' + window.location.search)
      }
    },
    generateRandomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  }
};
</script>

<style>
* {
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  font-family: PingFang HK;
  font-style: normal;
  font-size: 14px;
}

.van-button--warning {
  color: #fff;
  background: #ff976a;
}

.van-button--block {
  display: block;
  width: 100%;
}

.van-button {
  border: 0;
  outline: 0;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 1;
  -webkit-appearance: none;
  -webkit-font-smoothing: auto;

}

.app {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% 100%;
  overflow: hidden;
}

.app .header {
  padding-top: 181px;
}

.app .header .year-old {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #df0000;
  border-radius: 10px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

.app .btn-wrap {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.app .warning {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  line-height: 20px;
  width: 260px;
  margin: 20px auto 40px;
}

.app .my-node-container {
  position: absolute;
  z-index: -9;
}

.app #my-node {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
}

.app .answere {
  width: 314px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 30px 30px;
}

@media screen and (max-width: 750px) {
  .app {
    background-image: url(assets/bg-phone-BwdT3eq-.jpg);
  }

  .app .content {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .app .header {
    padding-top: 132px;
  }

  .app .header img {
    width: 305px;
  }
}

@media screen and (min-width: 750px) {
  .app {
    background-image: url(assets/bg-pc-PyXI2ksE.jpg);
  }

  .app .header {
    padding-top: 127px;
  }

  .app .header img {
    width: 500px;
  }
}

.app .el-input__icon img {
  display: block;
  width: 100%;
}
</style>
